<template>
  <!-- NAME[epic=销售] 销售汇总按业务员 -->

  <div class="orderTest-container">
    <el-form
      ref="form"
      :model="form"
      inline
      label-position="right"
      label-width="120px"
    >
      <el-form-item label="" prop="">
        <el-select
          v-model="form.time_type"
          clearable
          style="width: 140px"
          placeholder="时间类型"
        >
          <el-option
            v-for="(i, idx) in timeTypeSelect"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-date-picker
          v-model="time"
          type="daterange"
          range-separator="-"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          style="width: 240px"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="" prop="depart_id">
        <el-select
          v-model="form.depart_id"
          clearable
          style="width: 140px"
          placeholder="部门"
        >
          <el-option
            v-for="(i, idx) in bmList"
            :key="idx"
            :value="i.id"
            :label="i.text"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <sale-area-select-tree
          ref="saleAreaSelectTree"
          style="margin-right: 10px"
          @set-sale-select-id="selectArea"
        ></sale-area-select-tree>
      </el-form-item>
      <el-form-item label="" prop="staff_id">
        <el-select
          v-model="form.staff_id"
          clearable
          style="width: 140px"
          placeholder="业务员"
        >
          <el-option
            v-for="(i, idx) in staffList"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <brand-select
          ref="brandSelect"
          :duoxuan="true"
          style="width: 160px"
          @brand-select-change="brandChange"
        ></brand-select>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          @click.stop="
            () => {
              form.pageSize = 10
              form.pageNo = 1
              fetchData()
            }
          "
        >
          查询
        </el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="warning" @click.stop="clearForm">清空</el-button>
      </el-form-item>
      <el-form-item>
        <el-button @click="handleExport">导出</el-button>
      </el-form-item>
      <el-form-item>
        <el-button @click="handlePrint">打印</el-button>
      </el-form-item>
      <div class="more">
        <el-collapse v-model="activeNames">
          <el-collapse-item name="1">
            <template slot="title">
              <div class="df">
                <div class="df-a">更多筛选条件</div>
                <div class="df-b">
                  <!--                  <el-button-->
                  <!--                    type="primary"-->
                  <!--                    @click.stop="-->
                  <!--                      () => {-->
                  <!--                        form.pageSize = 10-->
                  <!--                        form.pageNo = 1-->
                  <!--                        fetchData()-->
                  <!--                      }-->
                  <!--                    "-->
                  <!--                  >-->
                  <!--                    查询-->
                  <!--                  </el-button>-->
                  <!--                  <el-button type="warning" @click.stop="clearForm">-->
                  <!--                    清空-->
                  <!--                  </el-button>-->
                  <!--                  <el-button>导出</el-button>-->
                  <!--                  <el-button>打印</el-button>-->
                </div>
              </div>
            </template>
            <el-form-item label="" prop="">
              <class-select
                ref="classSelect"
                @class-select-change="classChange"
              ></class-select>
            </el-form-item>
            <el-form-item label="" prop="">
              <goods-search
                ref="goodsSearch"
                :is-table="false"
                @select-goods-all="goodsSelect"
              ></goods-search>
            </el-form-item>
            <el-form-item label="" prop="channel_id">
              <el-select
                v-model="form.channel_id"
                clearable
                style="width: 140px"
                placeholder="渠道"
              >
                <el-option
                  v-for="(i, idx) in channels"
                  :key="idx"
                  :value="i.id"
                  :label="i.name"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="">
              <client-search
                ref="clientSearch"
                :popper-class="'selectDC'"
                @select-id="clientSelect"
              ></client-search>
            </el-form-item>
            <el-form-item label="" prop="bill_type">
              <el-select
                v-model="form.bill_type"
                clearable
                style="width: 140px"
                placeholder="来源"
              >
                <el-option
                  v-for="(i, idx) in lyList"
                  :key="idx"
                  :value="i.id"
                  :label="i.name"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="" prop="pay_status">
              <el-select
                v-model="form.pay_status"
                clearable
                style="width: 140px"
                placeholder="是否结清"
              >
                <el-option
                  v-for="(i, idx) in jqList"
                  :key="idx"
                  :value="i.id"
                  :label="i.name"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-collapse-item>
        </el-collapse>
      </div>
      <el-table
        v-loading="loading"
        stripe
        :data="list"
        show-summary
        :summary-method="summaryFunction"
      >
        <!-- 序号 -->
        <el-table-column align="center" type="index" width="50">
          <!-- <template slot="header">
            <el-popover popper-class="custom-table-checkbox" trigger="hover">
              <el-checkbox-group v-model="checkList">
                <el-checkbox
                  v-for="(item, index) in columns"
                  :key="index"
                  :label="item.label"
                ></el-checkbox>
              </el-checkbox-group>
              <el-button slot="reference" type="text">
                <vab-remix-icon icon="settings-line" />
              </el-button>
            </el-popover>
          </template> -->
        </el-table-column>
        <el-table-column
          v-for="(item, tableIndex) in finallyColumns"
          :key="tableIndex"
          :prop="item.prop"
          :label="item.label"
          width=""
          align="center"
        >
          <template #default="{ row }">
            <span
              v-if="
                item.label == '销售数量' ||
                item.label == '退货数量' ||
                item.label == '赠送数量'
              "
              style="border-bottom: 2px solid #00a6ff; cursor: pointer"
              @click="cellClick(row)"
            >
              {{ row[item.prop] }}
            </span>
            <div v-else>
              {{ row[item.prop] }}
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="form.pageNo"
        :layout="layout"
        :page-size="form.pageSize"
        :total="total"
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      ></el-pagination>
      <ruilang-display-dialog
        ref="RuilangDisplayDialog"
        bill_type="LYW"
        :data_id="1"
        :type="1"
        :summary-params="form"
      ></ruilang-display-dialog>
    </el-form>
    <el-dialog
      :title="goodstitle"
      :visible.sync="goods_show"
      center
      width="80%"
    >
      <div>
        <el-card v-if="goods_list.sale.data_list.length>0">
          <el-table
            ref="tabledata"
            v-loading="goods_loading"
            stripe
            show-summary
            :data="goods_list.sale.data_list"
          >
            <!--      :summary-method="getSummaries"-->
            <el-table-column
              prop="cust_name"
              label="销售客户"
              width=""
            ></el-table-column>
            <el-table-column
              prop="bill_code"
              label="单号"
              width=""
            >
              <template #default="{ row }">
                <span @click="checkOrder(row)">
                  {{ row.bill_code }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="goods_type_text"
              label="类型"
              width=""
            ></el-table-column>
            <el-table-column
              prop="quantity"
              label="数量"
              width=""
            ></el-table-column>
            <el-table-column
              prop="unit_name"
              label="单位"
              width=""
            ></el-table-column>
            <el-table-column
              prop="goods_price"
              label="售价"
              width=""
            ></el-table-column>
            <el-table-column
              prop="goods_money"
              label="金额"
              width=""
            ></el-table-column>
            <el-table-column
              prop="create_at"
              label="下单时间"
              width=""
            ></el-table-column>
          </el-table>
        </el-card>
        <el-card  v-if="goods_list.back.data_list.length>0">
          <el-table
            ref="tabledata"
            v-loading="goods_loading"
            stripe
            show-summary
            :data="goods_list.back.data_list"
          >
            <!--      :summary-method="getSummaries"-->
            <el-table-column
              prop="cust_name"
              label="退货客户"
              width=""
            ></el-table-column>
            <el-table-column
              prop="bill_code"
              label="单号"
              width=""
            >
              <template #default="{ row }">
                <span @click="checkOrder(row)">
                  {{ row.bill_code }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="goods_type_text"
              label="类型"
              width=""
            ></el-table-column>
            <el-table-column
              prop="quantity"
              label="数量"
              width=""
            ></el-table-column>
            <el-table-column
              prop="unit_name"
              label="单位"
              width=""
            ></el-table-column>
            <el-table-column
              prop="goods_price"
              label="退货价"
              width=""
            ></el-table-column>
            <el-table-column
              prop="goods_money"
              label="金额"
              width=""
            ></el-table-column>
            <el-table-column
              prop="create_at"
              label="下单时间"
              width=""
            ></el-table-column>
          </el-table>
        </el-card>
        <el-card v-if="goods_list.give.data_list.length>0">
          <el-table
            ref="tabledata"
            v-loading="goods_loading"
            stripe
            show-summary
            :data="goods_list.give.data_list"
          >
            <!--      :summary-method="getSummaries"-->
            <el-table-column
              prop="cust_name"
              label="赠送客户"
              width=""
            ></el-table-column>
            <el-table-column
              prop="bill_code"
              label="单号"
              width=""
            >
              <template #default="{ row }">
                <span @click="checkOrder(row)">
                  {{ row.bill_code }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="goods_type_text"
              label="类型"
              width=""
            ></el-table-column>
            <el-table-column
              prop="quantity"
              label="数量"
              width=""
            ></el-table-column>
            <el-table-column
              prop="unit_name"
              label="单位"
              width=""
            ></el-table-column>
            <el-table-column
              prop="create_at"
              label="下单时间"
              width=""
            ></el-table-column>
          </el-table>
        </el-card>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="ExprotExml">导 出</el-button>
        <el-button type="primary" @click="goods_show = false">确 定</el-button>
      </span>
    </el-dialog>
    <check-order ref="checkOrder"></check-order>
    <check ref="checkXS" />
  </div>
</template>
<script>
  import _ from 'lodash'
  import { saleStaffList } from '@/api/saleData'
  import { departmentList } from '@/api/performance'
  import { getStaffList } from '@/api/setPrice'
  import { clientCustChannel } from '@/api/ClientDetail'
  import SaleAreaSelectTree from '@/baseComponents/saleAreaSelectTree'
  import ClassSelect from '@/baseComponents/classSelect'
  import BrandSelect from '@/baseComponents/brandSelect'
  import GoodsSearch from '@/baseComponents/goodsSearch'
  import ClientSearch from '@/baseComponents/clientSearch'
  import { downloadFile, postAction } from '@/api/Employee'
  import RuilangDisplayDialog from '@/baseComponents/ruiLangDesign/ruilangDisplayDialog'

  import { day_n } from '@/utils/Time'
  import CheckOrder from "@/views/project/sale/bills/advanceOrder/components/checkOrder/index.vue";
  import check from "@/views/project/sale/bills/saleOrder/components/checkOrder/index.vue";
  export default {
    name: 'SummarySaleMan',
    components: {
      check,
      CheckOrder,
      ClassSelect,
      BrandSelect,
      GoodsSearch,
      ClientSearch,
      SaleAreaSelectTree,
      RuilangDisplayDialog,
    },
    data() {
      return {
        goods_list: {
          sale: {
            data_list: [],
            data_sum: {},
          },
          back: {
            data_list: [],
            data_sum: {},
          },
          give: {
            data_list: [],
            data_sum: {},
          },
        },
        goodstitle: '',
        goods_show: false,
        goods_loading: false,
        Exprotdata: {},
        loading: false,
        activeNames: ['0'],
        time: [],
        total: 0,
        layout: 'total, sizes, prev, pager, next, jumper',
        list: [],
        checkList: [
          '业务员',
          '品牌',
          '商品名称',
          '规格',
          '条码',
          '成本价',
          '销售数量',
          '销售金额',
          '销售成本合计',
          '销售毛利',
          '赠送数量',
          '赠送金额',
          '赠送成本合计',
          '退货数量',
          '退货金额',
          '退货成本合计',
          '净销售数量',
          '净销售金额',
          '净毛利',
        ],
        columns: [
          {
            order: 1,
            label: '业务员',
            prop: 'user_name',
            width: '',
          },
          {
            order: 1.1,
            label: '品牌',
            prop: 'brand_name',
            width: '',
          },
          {
            order: 2,
            label: '商品名称',
            prop: 'goods_name',
            width: '',
          },
          {
            order: 2,
            label: '规格',
            prop: 'specs',
            width: '',
          },
          {
            order: 2,
            label: '条码',
            prop: 'unit_code',
            width: '',
          },
          {
            order: 2,
            label: '成本价',
            prop: 'base_price',
            width: '',
          },
          {
            order: 3,
            label: '销售数量',
            prop: 'sale_count',
            width: '',
          },
          {
            order: 4,
            label: '销售金额',
            prop: 'sale_money',
            width: '',
          },
          {
            order: 5,
            label: '销售成本合计',
            prop: 'sale_chengben',
            width: '',
          },
          {
            order: 6,
            label: '销售毛利',
            prop: 'sale_maoli',
            width: '',
          },
          {
            order: 7,
            label: '赠送数量',
            prop: 'give_count',
            width: '',
          },
          {
            order: 7.5,
            label: '赠送金额',
            prop: 'give_money',
            width: '',
          },
          {
            order: 8,
            label: '赠送成本合计',
            prop: 'give_chengben',
            width: '',
          },
          {
            order: 9,
            label: '退货数量',
            prop: 'back_count',
            width: '',
          },
          {
            order: 9,
            label: '退货金额',
            prop: 'back_money',
            width: '',
          },
          {
            order: 9,
            label: '退货成本合计',
            prop: 'back_chengben',
            width: '',
          },
          {
            order: 9,
            label: '净销售数量',
            prop: 'rate_count',
            width: '',
          },
          {
            order: 9,
            label: '净销售金额',
            prop: 'rate_money',
            width: '',
          },
          {
            order: 9,
            label: '净毛利',
            prop: 'gross_profit_amount',
            width: '',
          },
        ],
        timeTypeSelect: [
          {
            id: 'create_at',
            name: '下单时间',
          },
          {
            id: 'arrive_at',
            name: '到货时间',
          },
          {
            id: 'pay_status_time',
            name: '结清时间',
          },
        ],
        form: {
          time_type: 'arrive_at',
          start_time: '', //开始时间
          end_time: '', //结束时间
          class_id: '', //品类id
          brand_id: '', //品牌id
          staff_id: '', //业务员id
          driver_id: '', //送货员id
          channel_id: '', //渠道id
          goods_id: '', //商品id
          cust_id: '', //客户id
          area_id: '', //区域id
          grade_id: '', //等级id
          bill_type: '', //单据类型1、车销 2、访销 3、网销 4、仓库直销
          pay_status: '', //结算结果 0未结清 1已结清 2坏账
          depart_id: '',
          pageNo: 1, //页数
          pageSize: 10, //条数
          order: '', //排序方法desc降序asc升序
          sort: '', //排序类型
        },
        bmList: [],
        staffList: [],
        channels: [],
        lyList: [
          //1、车销 2、访销 3、网销 4、仓库直销
          {
            id: 1,
            name: '车销',
          },
          {
            id: 2,
            name: '访销',
          },
          {
            id: 3,
            name: '网销',
          },
          {
            id: 4,
            name: '仓库直销',
          },
        ],
        jqList: [
          // 0未结清 1已结清 2坏账
          {
            id: 0,
            name: '未结清',
          },
          {
            id: 1,
            name: '已结清',
          },
          {
            id: 2,
            name: '坏账',
          },
        ],
        url: {
          Export: '/saleAdmin/sale-form/export-staff',
        },
        totalAll: {},
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {
      time(val) {
        if (val) {
          this.form.start_time = val[0]
          this.form.end_time = val[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    created() {
      this.time = day_n(90)
      this.form.start_time = this.time[0]
      this.form.end_time = this.time[1]
      this.getSelect()
      this.fetchData()
    },
    mounted() {},
    activated() {
      this.fetchData()
    },
    methods: {
      checkOrder(row) {
        if (/XS/.test(row.bill_code)) {
          console.log('row', row)
          this.$refs['checkXS'].isshowDialog = true
          // this.$refs['checkOrder'].orderStatus = row.bill_status_text
          this.$refs['checkXS'].id = row.order_id
        } else if (/XD/.test(row.bill_code)) {
          this.$refs['checkOrder'].showDialog()
          let text = ''
          if (row.check_status == 0) {
            text = row.bill_status_text
          } else {
            text = row.check_status_text
          }
          // this.$refs['checkOrder'].orderStatus2 = text
          // this.$refs['checkOrder'].orderStatus = row.bill_status_text
          this.$refs['checkOrder'].id = row.order_id
        }
        console.log(row)
      },
      ExprotExml() {
        downloadFile(
          '/saleAdmin/sale-form/export-staff-detail',
          this.goodstitle + '.xlsx',
          {...this.form,...this.Exprotdata}
        ).then((res) => {
          if (res.code == 200) {
            this.$message.success('导出成功')
          }
        })
      },
      cellClick(row) {
        this.goods_loading = true
        this.Exprotdata = { ...this.form,staff_id: row.staff_id, goods_id: row.goods_id }
        postAction('/saleAdmin/sale-form/staff-detail', this.Exprotdata).then((res) => {
          if (res.code == 200) {
            this.goods_list = res.data.data
            this.goodstitle =
              res.data.info.staff_name +
              '(' +
              res.data.info.goods_name +
              res.data.info.goods_spec +
              ')'
            this.goods_show = true
            this.goods_loading = false
          }
        })
      },
      handleExport() {
        downloadFile(this.url.Export, '销售明细(按业务员).xlsx', this.form)
      },
      async fetchData() {
        this.loading = true
        let { data, totalCount } = await saleStaffList(this.form)
        this.total = totalCount
        this.list = data.list
        this.totalAll = data.total_data
        this.loading = false
      },
      selectArea(val) {
        this.form.area_id = val
      },
      classChange(val) {
        this.form.class_id = val
      },
      brandChange(val) {
        this.form.brand_id = val
      },
      goodsSelect(val) {
        this.form.goods_id = val.goods_id
      },
      clientSelect(val) {
        this.form.cust_id = val
      },
      getSelect() {
        // 部门下拉
        departmentList().then((res) => {
          this.bmList = res.data.list
        })
        // 获取业务员下拉
        getStaffList().then((res) => {
          if (res.code == 200) this.staffList = res.data
        })
        // 渠道下拉
        clientCustChannel().then((d) => {
          this.channels = d.data
        })
      },
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.fetchData()
      },
      clearForm() {
        this.time = []
        this.$refs.form.resetFields()
        this.$refs.goodsSearch.resetForm()
        this.$refs.clientSearch.resetForm()
        this.$refs.brandSelect.resetForm()
        this.$refs.classSelect.resetForm()
        this.$refs.saleAreaSelectTree.resetForm()
        this.form.goods_id = ''
        this.form.cust_id = ''
        this.form.area_id = ''
      },
      // 表格合计fun
      summaryFunction(param) {
        const { columns, data } = param
        // 需要合计的下标
        let i = [7, 8, 10]
        let sums = []
        let a = this.totalAll
        sums[0] = '合计'

        sums[15 + 1] = a.back_chengben
        sums[13 + 1] = a.back_count
        sums[14 + 1] = a.back_money
        sums[11 + 1] = a.give_money // 赠送金额
        sums[12 + 1] = a.give_chengben // 赠送成本合计
        sums[10 + 1] = a.give_count
        sums[18 + 1] = a.gross_profit_amount
        sums[16 + 1] = a.rate_count
        sums[17 + 1] = a.rate_money
        sums[8 + 1] = a.sale_chengben
        sums[6 + 1] = a.sale_count
        sums[9 + 1] = a.sale_maoli
        sums[7 + 1] = a.sale_money

        // let val = data.map((item) => {
        //   console.log(item)
        // })
        // columns.forEach((col, idx) => {
        //   i.forEach((n) => {
        //     if (idx == n) {
        //       console.log(col.property)
        //       const val = data.map((item) => Number(item[col.property]))
        //       console.log(val)
        //       if (!val.every((value) => isNaN(value))) {
        //         const a = val.reduce((total, num) => {
        //           if (!isNaN(total)) {
        //             return total + num
        //           }
        //         })
        //         sums[n] = a + ' 元'
        //       }
        //     }
        //   })
        //   if (idx == 0) {
        //     sums[idx] = '合计'
        //   }
        // })
        console.log('sss', sums)
        return sums
      },

      handlePrint() {
        this.$refs['RuilangDisplayDialog'].dialogFormVisible = true
      },
    },
  }
</script>
<style lang="scss" scoped>
  .df {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
</style>
