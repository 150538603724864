var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            inline: "",
            "label-position": "right",
            "label-width": "120px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: { clearable: "", placeholder: "时间类型" },
                  model: {
                    value: _vm.form.time_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "time_type", $$v)
                    },
                    expression: "form.time_type",
                  },
                },
                _vm._l(_vm.timeTypeSelect, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { value: i.id, label: i.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c("el-date-picker", {
                staticStyle: { width: "240px" },
                attrs: {
                  type: "daterange",
                  "range-separator": "-",
                  "value-format": "yyyy-MM-dd",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                },
                model: {
                  value: _vm.time,
                  callback: function ($$v) {
                    _vm.time = $$v
                  },
                  expression: "time",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "depart_id" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: { clearable: "", placeholder: "部门" },
                  model: {
                    value: _vm.form.depart_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "depart_id", $$v)
                    },
                    expression: "form.depart_id",
                  },
                },
                _vm._l(_vm.bmList, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { value: i.id, label: i.text },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c("sale-area-select-tree", {
                ref: "saleAreaSelectTree",
                staticStyle: { "margin-right": "10px" },
                on: { "set-sale-select-id": _vm.selectArea },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "staff_id" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: { clearable: "", placeholder: "业务员" },
                  model: {
                    value: _vm.form.staff_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "staff_id", $$v)
                    },
                    expression: "form.staff_id",
                  },
                },
                _vm._l(_vm.staffList, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { value: i.id, label: i.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c("brand-select", {
                ref: "brandSelect",
                staticStyle: { width: "160px" },
                attrs: { duoxuan: true },
                on: { "brand-select-change": _vm.brandChange },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return function () {
                        _vm.form.pageSize = 10
                        _vm.form.pageNo = 1
                        _vm.fetchData()
                      }.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(" 查询 ")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "warning" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.clearForm.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("清空")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("el-button", { on: { click: _vm.handleExport } }, [
                _vm._v("导出"),
              ]),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("el-button", { on: { click: _vm.handlePrint } }, [
                _vm._v("打印"),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "more" },
            [
              _c(
                "el-collapse",
                {
                  model: {
                    value: _vm.activeNames,
                    callback: function ($$v) {
                      _vm.activeNames = $$v
                    },
                    expression: "activeNames",
                  },
                },
                [
                  _c(
                    "el-collapse-item",
                    { attrs: { name: "1" } },
                    [
                      _c("template", { slot: "title" }, [
                        _c("div", { staticClass: "df" }, [
                          _c("div", { staticClass: "df-a" }, [
                            _vm._v("更多筛选条件"),
                          ]),
                          _c("div", { staticClass: "df-b" }),
                        ]),
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { label: "", prop: "" } },
                        [
                          _c("class-select", {
                            ref: "classSelect",
                            on: { "class-select-change": _vm.classChange },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "", prop: "" } },
                        [
                          _c("goods-search", {
                            ref: "goodsSearch",
                            attrs: { "is-table": false },
                            on: { "select-goods-all": _vm.goodsSelect },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "", prop: "channel_id" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "140px" },
                              attrs: { clearable: "", placeholder: "渠道" },
                              model: {
                                value: _vm.form.channel_id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "channel_id", $$v)
                                },
                                expression: "form.channel_id",
                              },
                            },
                            _vm._l(_vm.channels, function (i, idx) {
                              return _c("el-option", {
                                key: idx,
                                attrs: { value: i.id, label: i.name },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "" } },
                        [
                          _c("client-search", {
                            ref: "clientSearch",
                            attrs: { "popper-class": "selectDC" },
                            on: { "select-id": _vm.clientSelect },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "", prop: "bill_type" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "140px" },
                              attrs: { clearable: "", placeholder: "来源" },
                              model: {
                                value: _vm.form.bill_type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "bill_type", $$v)
                                },
                                expression: "form.bill_type",
                              },
                            },
                            _vm._l(_vm.lyList, function (i, idx) {
                              return _c("el-option", {
                                key: idx,
                                attrs: { value: i.id, label: i.name },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "", prop: "pay_status" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "140px" },
                              attrs: { clearable: "", placeholder: "是否结清" },
                              model: {
                                value: _vm.form.pay_status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "pay_status", $$v)
                                },
                                expression: "form.pay_status",
                              },
                            },
                            _vm._l(_vm.jqList, function (i, idx) {
                              return _c("el-option", {
                                key: idx,
                                attrs: { value: i.id, label: i.name },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: {
                stripe: "",
                data: _vm.list,
                "show-summary": "",
                "summary-method": _vm.summaryFunction,
              },
            },
            [
              _c("el-table-column", {
                attrs: { align: "center", type: "index", width: "50" },
              }),
              _vm._l(_vm.finallyColumns, function (item, tableIndex) {
                return _c("el-table-column", {
                  key: tableIndex,
                  attrs: {
                    prop: item.prop,
                    label: item.label,
                    width: "",
                    align: "center",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            item.label == "销售数量" ||
                            item.label == "退货数量" ||
                            item.label == "赠送数量"
                              ? _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "border-bottom": "2px solid #00a6ff",
                                      cursor: "pointer",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.cellClick(row)
                                      },
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(row[item.prop]) + " ")]
                                )
                              : _c("div", [
                                  _vm._v(" " + _vm._s(row[item.prop]) + " "),
                                ]),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
            ],
            2
          ),
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.form.pageNo,
              layout: _vm.layout,
              "page-size": _vm.form.pageSize,
              total: _vm.total,
              background: "",
            },
            on: {
              "current-change": _vm.handleCurrentChange,
              "size-change": _vm.handleSizeChange,
            },
          }),
          _c("ruilang-display-dialog", {
            ref: "RuilangDisplayDialog",
            attrs: {
              bill_type: "LYW",
              data_id: 1,
              type: 1,
              "summary-params": _vm.form,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.goodstitle,
            visible: _vm.goods_show,
            center: "",
            width: "80%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.goods_show = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _vm.goods_list.sale.data_list.length > 0
                ? _c(
                    "el-card",
                    [
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.goods_loading,
                              expression: "goods_loading",
                            },
                          ],
                          ref: "tabledata",
                          attrs: {
                            stripe: "",
                            "show-summary": "",
                            data: _vm.goods_list.sale.data_list,
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "cust_name",
                              label: "销售客户",
                              width: "",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "bill_code",
                              label: "单号",
                              width: "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      _c(
                                        "span",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.checkOrder(row)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(row.bill_code) + " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2597376506
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "goods_type_text",
                              label: "类型",
                              width: "",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "quantity",
                              label: "数量",
                              width: "",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "unit_name",
                              label: "单位",
                              width: "",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "goods_price",
                              label: "售价",
                              width: "",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "goods_money",
                              label: "金额",
                              width: "",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "create_at",
                              label: "下单时间",
                              width: "",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.goods_list.back.data_list.length > 0
                ? _c(
                    "el-card",
                    [
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.goods_loading,
                              expression: "goods_loading",
                            },
                          ],
                          ref: "tabledata",
                          attrs: {
                            stripe: "",
                            "show-summary": "",
                            data: _vm.goods_list.back.data_list,
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "cust_name",
                              label: "退货客户",
                              width: "",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "bill_code",
                              label: "单号",
                              width: "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      _c(
                                        "span",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.checkOrder(row)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(row.bill_code) + " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2597376506
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "goods_type_text",
                              label: "类型",
                              width: "",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "quantity",
                              label: "数量",
                              width: "",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "unit_name",
                              label: "单位",
                              width: "",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "goods_price",
                              label: "退货价",
                              width: "",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "goods_money",
                              label: "金额",
                              width: "",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "create_at",
                              label: "下单时间",
                              width: "",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.goods_list.give.data_list.length > 0
                ? _c(
                    "el-card",
                    [
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.goods_loading,
                              expression: "goods_loading",
                            },
                          ],
                          ref: "tabledata",
                          attrs: {
                            stripe: "",
                            "show-summary": "",
                            data: _vm.goods_list.give.data_list,
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "cust_name",
                              label: "赠送客户",
                              width: "",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "bill_code",
                              label: "单号",
                              width: "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      _c(
                                        "span",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.checkOrder(row)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(row.bill_code) + " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2597376506
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "goods_type_text",
                              label: "类型",
                              width: "",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "quantity",
                              label: "数量",
                              width: "",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "unit_name",
                              label: "单位",
                              width: "",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "create_at",
                              label: "下单时间",
                              width: "",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.ExprotExml } }, [
                _vm._v("导 出"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.goods_show = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c("check-order", { ref: "checkOrder" }),
      _c("check", { ref: "checkXS" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }